<template>
  <div
    class="brainstorm-item"
    :class="{
      'brainstorm-item--active': isActive,
      'brainstorm-item--desktop': isDesktop,
    }"
    @click="changeBrainstormActivity"
  >
    <div class="brainstorm-item__row">
      <div class="brainstorm-item__name">{{ brainstorm.name }}</div>
      <div class="brainstorm-item__ideas-count" :class="ideasCountClass">
        {{ brainstorm.ideas_count }}
      </div>
    </div>
    <div v-show="isActive" class="brainstorm-item__controls">
      <div class="controls__status">
        {{ $t("Brainstorm.topicLaunched") }}
      </div>
      <div class="controls">
        <button class="controls__button" :class="buttonClass" @click.stop="changeBrainstormStatus">
          {{ buttonText }}
        </button>
      </div>
      <div class="controls__notification">
        {{ $t("Brainstorm.controlsNotification") }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { room, socket, speakerBrainstorm } from "@/store/modules/store.namespaces";
import { SPEAKER_BRAINSTORMS } from "@/store/modules/speaker/modules/brainstorm/getter-types";
import {
  CHANGE_STATUS,
  SET_BRAINSTORM,
} from "@/store/modules/speaker/modules/brainstorm/action-types";
import { SET_ACTIVE_BRAINSTORM } from "@/store/modules/speaker/modules/brainstorm/mutation-types";
import { SET_SOCKET_OBJ } from "@/store/modules/socket/mutation-types";

export default {
  name: "BrainstormItem",
  props: {
    brainstorm: Object,
  },
  computed: {
    ...mapGetters(speakerBrainstorm, {
      brainstorms: SPEAKER_BRAINSTORMS,
    }),
    ...mapState(speakerBrainstorm, ["activeBrainstormId"]),
    ...mapState(room, ["roomNumber"]),
    isDesktop() {
      return this.$mqLaptop;
    },
    isActive() {
      return this.activeBrainstormId === Number(this.brainstorm.id);
    },
    buttonClass() {
      let classname = "";
      switch (this.brainstorm.status) {
        case 1:
          classname = "controls__button--blue";
          break;
        case 2:
          classname = "controls__button--red";
          break;
      }

      return classname;
    },
    buttonText() {
      let buttonText = "";
      switch (this.brainstorm.status) {
        case 1:
          buttonText = this.$t("Brainstorm.showIdeas");
          break;
        case 2:
          buttonText = this.$t("Brainstorm.hideIdeas");
          break;
      }

      return buttonText;
    },
    ideasCountClass() {
      return this.brainstorm.ideas_count === 0 ? "brainstorm-item__ideas-count--gray" : "";
    },
  },
  methods: {
    ...mapActions(speakerBrainstorm, {
      changeStatus: CHANGE_STATUS,
      setBrainstormAction: SET_BRAINSTORM,
    }),
    ...mapMutations(speakerBrainstorm, {
      setActiveBrainstorm: SET_ACTIVE_BRAINSTORM,
    }),
    ...mapMutations(socket, {
      setSocketObj: SET_SOCKET_OBJ,
    }),
    changeBrainstormActivity() {
      const status = this.brainstorm.status === 0 ? 1 : 0;

      if (status === 1) {
        if (this.activeBrainstormId) {
          this.$dialog
            .confirm(
              {
                title: this.$t("Brainstorm.dialogStartTitle"),
                body: this.$t("Brainstorm.dialogStartBody"),
              },
              {
                okText: this.$t("Brainstorm.dialogOkText"),
                cancelText: this.$t("Brainstorm.dialogCancelText"),
              },
            )
            .then(() => {
              this.changeStatus({
                id: this.activeBrainstormId,
                status: 0,
              });

              this.setBrainstorm(status);
            });
        } else {
          this.setBrainstorm(status);
        }
      } else {
        this.$dialog
          .confirm(
            {
              title: this.$t("Brainstorm.dialogStopTitle"),
              body: this.$t("Brainstorm.dialogStopBody"),
            },
            {
              okText: this.$t("Brainstorm.dialogOkText"),
              cancelText: this.$t("Brainstorm.dialogCancelText"),
            },
          )
          .then(() => {
            this.$bus.$emit("speakerBrainstormIsChangingActivity");
            this.setActiveBrainstorm(null);
            this.dispatchBrainstormStatus(status);
          });
      }
    },
    setBrainstorm(status) {
      this.$bus.$emit("speakerBrainstormIsChangingActivity");
      this.setBrainstormAction(this.brainstorm.id).then(() => {
        const data = {
          type: "setBrainstorm",
          obj: {
            room: this.roomNumber,
            idBS: this.brainstorm.id,
            statusBS: status,
          },
        };
        this.setSocketObj(data);
        this.setActiveBrainstorm(this.brainstorm.id);
        this.dispatchBrainstormStatus(status);
      });
    },
    changeBrainstormStatus() {
      if (!this.isActive) {
        return false;
      }

      const status = this.brainstorm.status === 1 ? 2 : 1;
      this.dispatchBrainstormStatus(status);
    },
    dispatchBrainstormStatus(status) {
      this.changeStatus({
        id: this.brainstorm.id,
        status,
      }).then(() => {
        const data = {
          type: "changeBrainstormStatus",
          obj: {
            room: this.roomNumber,
            idBS: this.brainstorm.id,
            statusBS: status,
          },
        };
        this.setSocketObj(data);
        this.$bus.$emit("speakerBrainstormActivityIsChanged");
      });
    },
  },
};
</script>

<style scoped lang="less">
.brainstorm-item {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  background-color: #ffffff;
  margin-bottom: 5px;
  padding: 10px 15px;
  cursor: pointer;
  position: relative;

  &--active,
  &:hover {
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.15);
  }

  &--active {
    .brainstorm-item__row {
      margin-bottom: 5px;
    }

    .brainstorm-item__name {
      font-weight: bold;
    }
  }

  &__name {
    font-size: 15px;
    line-height: 1.3;
    color: #303030;
  }

  &__ideas-count {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: content-box;
    flex-basis: 8px;
    font-size: 14px;
    line-height: 1.3;
    color: #ffffff;
    background-color: #f28b00;
    border-radius: 22px;
    padding: 3px 8px;

    &--gray {
      background-color: #d2d2d2;
    }
  }

  &__controls {
  }

  .controls {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;

    &__status {
      font-size: 12px;
      line-height: 12px;
      color: #999999;
      display: flex;
      align-items: center;

      &:before {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        margin-right: 10px;
        background-color: #47bd34;
        border-radius: 50%;
      }
    }

    &__button {
      min-width: 116px;
      min-height: 44px;
      padding: 5px 40px;
      margin: 0;
      border: none;
      border-radius: 5px;
      background-color: #f28b00;
      font-size: 14px;
      line-height: normal;
      color: #ffffff;
      cursor: pointer;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &--disabled {
        opacity: 0.5;
      }

      &--blue {
        background-color: #00a3ff;
      }

      &--red {
        background-color: #ff2416;
      }

      &-icon {
        margin-right: 5px;

        &--close {
          font-size: 2em;
          line-height: 0.5;

          &:before {
            content: "\00d7";
          }
        }
      }
    }

    &__no-ideas {
      font-size: 15px;
      color: #ff2416;
    }

    &__notification {
      font-size: 12px;
      text-align: center;
      color: #999999;
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &--desktop {
    margin-bottom: 15px;
    padding-top: 20px;
    padding-bottom: 20px;

    &:hover {
      box-shadow: none;
    }

    &__name {
      margin-right: 10px;
    }

    .controls__button {
      min-width: auto;
      min-height: auto;
      padding: 10px 40px;
      font-size: 15px;
    }
  }
}
</style>
