<template>
  <div class="brainstorm" :class="{ 'brainstorm--desktop': $mqLaptop }">
    <div class="brainstorm__inner">
      <div class="title">{{ $t("Brainstorm.topicsList") }}</div>
      <brainstorm-list v-if="brainstorms.length > 0" :isLoading="isLoading"></brainstorm-list>
      <brainstorm-no-items v-else> </brainstorm-no-items>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import redirectNotMainSpeakerMixin from "@/components/speaker/mixins/redirectNotMainSpeakerMixin";
import { room, speakerBrainstorm } from "@/store/modules/store.namespaces";
import { SPEAKER_BRAINSTORMS } from "@/store/modules/speaker/modules/brainstorm/getter-types";
import { GET_BRAINSTORMS } from "@/store/modules/speaker/modules/brainstorm/action-types";
import BrainstormNoItems from "./components/BrainstormNoItems";
import BrainstormList from "./components/BrainstormList";

export default {
  name: "BrainstormControl",
  components: {
    BrainstormList,
    BrainstormNoItems,
  },
  mixins: [redirectNotMainSpeakerMixin],
  data() {
    return {
      isLoading: true,
      interval: null,
    };
  },
  componets: {
    BrainstormList,
  },
  computed: {
    ...mapGetters(speakerBrainstorm, {
      brainstorms: SPEAKER_BRAINSTORMS,
    }),
    ...mapState(room, ["roomNumber"]),
  },
  watch: {
    roomNumber(newValue) {
      if (newValue) {
        this.getBrainstorms(true);
      }
    },
  },
  methods: {
    ...mapActions(speakerBrainstorm, {
      getSpeakerBrainStorms: GET_BRAINSTORMS,
    }),
    getBrainstorms(showLoader = false) {
      if (showLoader) {
        this.isLoading = true;
      }
      this.getSpeakerBrainStorms()
        .then(() => {
          if (showLoader) {
            this.isLoading = false;
          }
        })
        .catch(() => {
          if (showLoader) {
            this.isLoading = false;
          }
        });
    },
  },
  created() {
    if (this.roomNumber) {
      this.getBrainstorms(true);
    }

    this.interval = setInterval(() => {
      this.getBrainstorms();
    }, 5 * 1000);
  },
  destroyed() {
    clearInterval(this.interval);
  },
};
</script>

<style scoped lang="less">
.brainstorm {
  width: 100%;
  padding: 15px;
  flex-grow: 1;
  background-color: #f6f6f6;

  &--desktop {
    background-color: #ffffff;
    flex-grow: 0;
  }

  &__inner {
    max-width: 561px;
    margin: 0 auto;
  }
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
}

.brainstorm-fixed-buttom {
  min-width: 116px;
  min-height: 44px;
  padding: 5px 15px;
  margin: 0;
  border: none;
  border-radius: 32px;
  background-color: #ff2416;
  font-size: 14px;
  line-height: normal;
  color: #ffffff;
  cursor: pointer;
  outline: none;
  position: fixed;
  bottom: 15px;
  right: 15px;
}
</style>
