<template>
  <div class="brainstorm-no-items">
    <div class="brainstorm-no-items__title">
      {{ $t("Brainstorm.noTopicsYet") }}
    </div>
    <div class="brainstorm-no-items__body">
      {{ $t("Brainstorm.noTopicsYetDesc") }}
    </div>
  </div>
</template>

<script>
export default {
  name: "BrainstormNoItems",
};
</script>

<style scoped lang="less">
.brainstorm-no-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__title {
    font-size: 18px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 15px;
  }

  &__body {
    font-size: 15px;
    color: #303030;
  }
}
</style>
