<template>
  <div v-if="isLoadingLocal" class="loader">
    <loader></loader>
  </div>
  <div
    v-else-if="brainstorms && brainstorms.length > 0"
    class="brainstorm-list"
  >
    <brainstorm-item
      v-for="brainstorm in brainstorms"
      :key="brainstorm.id"
      :brainstorm="brainstorm"
    ></brainstorm-item>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Loader from "@/components/common/elements/Loader";
import BrainstormItem from "./BrainstormItem";
import { speakerBrainstorm } from "@/store/modules/store.namespaces";
import { SPEAKER_BRAINSTORMS } from "@/store/modules/speaker/modules/brainstorm/getter-types";

export default {
  name: "BrainstormList",
  components: {
    Loader,
    BrainstormItem,
  },
  props: {
    isLoading: Boolean,
  },
  data() {
    return {
      isLoadingLocal: this.isLoading,
    };
  },
  watch: {
    isLoading(newVal) {
      this.isLoadingLocal = newVal;
    },
  },
  computed: {
    ...mapGetters(speakerBrainstorm, {
      brainstorms: SPEAKER_BRAINSTORMS,
    }),
  },
  mounted() {
    this.$bus.$on("speakerBrainstormIsChangingActivity", () => {
      this.isLoadingLocal = true;
    });

    this.$bus.$on("speakerBrainstormActivityIsChanged", () => {
      this.isLoadingLocal = false;
    });
  },
  beforeDestroy() {
    this.$bus.$off("speakerBrainstormIsChangingActivity");
    this.$bus.$off("speakerBrainstormActivityIsChanged");
  },
};
</script>

<style scoped lang="less">
.loader {
  margin: 20px auto;
}
</style>
