<script>
import { mapState, mapMutations } from "vuex";
import { auth, room, socket } from "@/store/modules/store.namespaces";
import { SET_USER_IS_MAIN } from "@/store/modules/auth/mutation-types";
import { SOCKET_CONNECT } from "@/store/modules/socket/action-types";
import { SET_SOCKET_OBJ } from "@/store/modules/socket/mutation-types";
import { SpeakerRouteName } from "@/constants/router/router-const";

const TIME_BEFORE_REDIRECT = 5000;

export default {
  name: "redirectNotMainSpeakerMixin",
  data() {
    return {
      unsubscribe: null,
      unsubscribeAction: null,
      isUnsubscribed: false,
      isModalOpen: false,
    };
  },
  computed: {
    ...mapState(auth, ["user"]),
    ...mapState(room, {
      room: "roomNumber",
    }),
  },
  mounted() {
    this.subscribeToStore();
    this.isUnsubscribed = false;

    if (this.user.isMain === false) {
      this.redirect();
    }
  },
  activated() {
    if (this.isUnsubscribed) {
      this.isUnsubscribed = false;
      this.subscribeToStore();
    }
  },
  deactivated() {
    this.isUnsubscribed = true;
    this.unsubscribe();
  },
  beforeDestroy() {
    this.isUnsubscribed = true;
    this.unsubscribe();
    this.unsubscribeAction();
  },
  methods: {
    ...mapMutations(socket, {
      setSocketObj: SET_SOCKET_OBJ,
    }),
    ...mapMutations(auth, {
      setSpeakerIsMain: SET_USER_IS_MAIN,
    }),
    subscribeToStore() {
      this.unsubscribe = this.$store.subscribe(mutation => {
        if (mutation.type === `${auth}/${SET_USER_IS_MAIN}` && mutation.payload.isMain === false) {
          // this.redirect();
        }
      });

      this.unsubscribeAction = this.$store.subscribeAction({
        after: (action, state) => {
          if (
            action.type === `${socket}/${SOCKET_CONNECT}` &&
            state.auth.user.isMain === undefined
          ) {
            // this.sendSpeaker();
          }
        },
      });
    },
    redirect() {
      let redirectTimeoutId = null;
      if (!this.isModalOpen) {
        this.isModalOpen = true;
        this.$dialog
          .alert(
            {
              title: this.$t("Brainstorm.dialogLostControlTitle"),
            },
            {
              okText: this.$t("Brainstorm.dialogUnderstandText"),
              reverse: true,
              customClass: "dg-alert",
            },
          )
          .then(() => {
            if (redirectTimeoutId) {
              clearTimeout(redirectTimeoutId);
              this.$dialog.destroy();
            }
            this.isModalOpen = false;
            this.changeRoute();
          });

        redirectTimeoutId = setTimeout(() => {
          this.$dialog.destroy();
          this.changeRoute();
        }, TIME_BEFORE_REDIRECT);
      }
    },
    changeRoute() {
      const routeName = this.room ? SpeakerRouteName.Presentations : SpeakerRouteName.Rooms;
      this.$router.push({
        name: routeName,
        ...(this.room ? { params: { id: this.room } } : {}),
      });
    },
    sendSpeaker() {
      const data = {
        type: "speaker",
        obj: {
          id: this.user.id,
        },
      };
      this.setSocketObj(data);
    },
  },
};
</script>
